





























import { Mixins, Component } from "vue-property-decorator";
import AppMixin from "@/mixins/App";
import SectionPage from "@/components/common/SectionPage.vue";
import SectionContent from "@/components/common/SectionContent.vue";
import ServicesBox from "@/modules/services/components/ServicesBox.vue";

@Component({
  components: {
    ServicesBox,
    SectionPage,
    SectionContent
  }
})
export default class Services extends Mixins(AppMixin) {
  mounted() {
    this.load();
  }

  async load() {
    const obCategory = this.category("service-list");

    if (!obCategory || this.loading) {
      return;
    }

    this.loading = true;
    await this.articles
      .category(obCategory.id)
      .limit(1)
      .fetch();

    if (this.articles.length) {
      this.article = this.articles.first();
    }

    this.loading = false;
  }

  get articleStyle() {
    return this.article
      ? {
          "background-image": `url("${this.article.preview_image}")`
        }
      : {};
  }

  get obCategory() {
    return this.category("services");
  }
}
